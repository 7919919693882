<template>
<div :class="{'elem-feed': true, 'full-size radius box-shadow feed-alone': this.expanded}">
  <div class="head-feed">
    <div class="elem-footer">
      <div class="pic-name">
        <div class="profile-pic"
          :class="posterPicture ? '' : 'empty'"
          :style=" posterPicture !== '' ? `background-image:url(${posterPicture})` : ''"
        >
        </div>
        <div class="profile-user">
          <div class="name-user"
            @click.exact="() => switchTab(`talent/${post.owner._id}`)"
            @click.ctrl="() => switchNewTab(`talent/${post.owner._id}`)"
            @auxclick="() => switchNewTab(`talent/${post.owner._id}`)"
            v-if="post.owner.__t === 'Talent'"
          >
            {{ownerString}}
          </div>
          <div class="name-user"
            @click.exact="() => switchTab(`company/${post.owner._id}`)"
            @click.ctrl="() => switchNewTab(`company/${post.owner._id}`)"
            @auxclick="() => switchNewTab(`company/${post.owner._id}`)"
            v-else-if="post.ownerType === 'Company'"
          >
            {{ownerString}}
          </div>
          <div class="name-user"
            v-else
          >
            {{ownerString}}
          </div>
          <div class="time-post">
            {{calcDateFromNow(this.post.createdAt)}}
          </div>
        </div>
        <div class="cont-show-more">
          <div  class="btn-green small radius-small expand-section"
            @click="expand" v-if="!this.expanded && this.oversized">
              Show More
          </div>
        </div>
      </div>
      <!-- <div class="global-param" v-if="post.youOwn && !hideOption">
        <div class="param ghost"></div>
        <div class="param" >
          <img src="@/assets/pictos/picto_param_purple.svg">
          <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
        </div>
        <div class="cont-param">
          <div class="elem-param"  @click="deletePost">{{ $t("delete") }}</div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="top-feed" ref="feedContent">
    <div class="head-feed">
      <p class="text-post" v-if="post.text !== ''">
        {{ post.text }}
      </p>
      <p class="text-post" v-else><br></p>
    </div>
    <div class="visual-feed radius-small"
      v-if="post.attachments && post.attachments.length > 0"
      :class="post.attachments[0].type === 'Image'?'box-shadow':''"
    >
      <img
      v-if="post.attachments[0].type === 'Image'"
        :src="post.attachments[0].data"
        :alt="decodeURI(post.attachments[0].data.split('/')[post.attachments[0]
          .data.split('/').length-1])"
      >
      <video
        v-if="post.attachments[0].type === 'Video'"
        controls
        class="preview" :src="post.attachments[0].data">
      </video>
      <audio controls class="doc audio"
        v-else-if="post.attachments[0].type === 'Audio'"
        :src="post.attachments[0].data"
      ></audio>
      <embed class="preview pdf-preview"
        v-else-if="post.attachments[0].type === 'Application' || post.attachments[0].type === 'Pdf'"
        :src="post.attachments[0].data"
      />
    </div>
  </div>
  <div class="footer-feed" v-if="!viewonly">
    <div class="elem-footer">
      <div class="elem-lightbulb" @click="likePost">
        <div class="picto" :class="{ liked : post.youLiked }">
            <img :src="require('@/assets/pictos/picto_lightbulb_white.svg')">
            <img class="liked" :src="require('@/assets/pictos/picto_lightbulb_darkpurple.svg')">
        </div>
        <div class="label">
            <span class="tlabel">{{ $t("recommend") }}</span>
            <span v-if="post.likes.length > 0">({{post.likes.length}})</span>
        </div>
      </div>
      <social-share :url="'/post/'+this.post._id"
        :title="`Post by ${ownerString} on Quasting`"
        :description="post.text"
         />
    </div>
  </div>
</div>

</template>

<script>
import calcDateFromNow from '@/modules/mixins/calcDateFromNow';
// import MediaView from '@/components/UniversalComponents/Media/mediaView.vue';
import socialShare from '@/components/UniversalComponents/SocialMedia/socialShareList.vue';

export default {
  name: 'Post',
  props: ['post', 'hideOption', 'fullSize', 'viewonly'],
  mixins: [calcDateFromNow],
  components: {
    // MediaView,
    socialShare,
  },
  data() {
    return {
      owner: {},
      isLiked: false,
      comments: [],
      shares: [],
      bookmarks: [],
      optionO: false,
      shareOpen: false,
      expanded: false,
      isMounted: false,
    };
  },
  computed: {
    ownerString() {
      if (this.post.owner.name.firstName) {
        return `${this.post.owner.name.firstName} ${this.post.owner.name.lastName}`;
      }
      return this.post.owner.name;
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    posterPicture() {
      if (this.post.owner.profilePicture) {
        return this.post.owner.profilePicture;
      }
      if (this.post.owner.companyPicture) {
        return this.post.owner.companyPicture;
      }
      return '';
    },
    oversized() {
      if (this.isMounted) {
        return this.$refs.feedContent && this.$refs.feedContent.clientHeight > 200;
      }
      return false;
    },

  },
  mounted() {
    this.post.likes.forEach((element) => {
      if (element.owner._id === this.userData.id) {
        this.isLiked = true;
      }
    });
    this.expanded = !!this.fullSize;
    this.isMounted = true;
  },
  methods: {
    deletePost() {
      this.$store.dispatch('feed/deletePost', this.post._id);
    },
    switchTab(value) {
      window.open(`${window.location.origin}/${value}`, '_blank');
    },
    switchNewTab(value) {
      window.open(`${window.location.origin}/${value}`, '_blank');
    },
    likePost() {
      this.$store.dispatch('feed/likePost', this.post._id).then((data) => {
        this.$emit('update-like', data);
      });
    },
    redirect() {
      if (this.post.ownerType === 'Talent') {
        this.$router.push(`/talent/${this.post.owner._id}`);
      }
    },
    openOption() {
      this.optionO = !this.optionO;
    },
    expand() {
      this.$router.push(`/post/${this.post._id}`);
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .splide__slide{
    .elem-feed{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .head-feed{
        width: 100%;
        .elem-footer{
          margin-right: 0;
          width: 100%;
          .pic-name{
            width: 100%;
            .profile-user{
              width: calc(59% - 1vh);
              margin-right: 1vh;
              .name-user{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .cont-show-more{
              display: flex;
              flex-wrap: wrap;
              justify-self: flex-end;
              .btn-green{
                font-size: 1.2vh;
              }
            }
          }
        }
      }
      .top-feed{
        max-height: 31vh;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .footer-feed{
        position: relative;
        .elem-footer{
          margin-right: 0;
        }
        .social_global .cont-rs-elem .rs_elem {
          height: 1.5vh;
          width: 1.5vh;
        }
      }
    }
  }
  .excerp-post{
    margin-top: 0.5vh;
    margin-bottom: 1vh;
    word-break: break-all;
  }
</style>
