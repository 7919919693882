<template>
<div class="cont-post-viewer" :class="this.$store.state.auth.loggedIn?'':'not-connected'" >
  <div class="cont-btn">
    <div
      class="btn-green radius"
      @click="() => goBackFeed()"
    >
      Go Back to Feed
    </div>
  </div>
  <post-view :post="this.post" :fullSize="true" v-if="loaded" @update-like="updateLike" />
</div>
</template>

<script>
import PostView from '@/components/UniversalComponents/Feed/_post.vue';

export default {
  name: 'PostViewer',
  props: ['postId'],
  components: {
    PostView,
  },
  data() {
    return {
      loaded: false,
      post: {},
    };
  },
  methods: {
    updateLike(data) {
      this.$set(this.post, 'likes', data.likes);
      if (this.$store.state.auth.loggedIn) {
        if (this.post.likes.find((like) => like.owner === this.$store.state.user._id)) {
          this.post.youLiked = true;
        } else {
          this.post.youLiked = false;
        }
      }
    },
    goBackFeed() {
      this.$router.push('/feed').catch(() => {});
    },
  },
  mounted() {
    this.$api.get(`post/${this.postId}`).then((res) => {
      this.post = res.data;
      if (this.$store.state.auth.loggedIn) {
        if (this.post.likes.find((like) => like.owner._id === this.$store.state.user._id)) {
          this.post.youLiked = true;
        } else {
          this.post.youLiked = false;
        }

        if (this.post.owner._id === this.$store.state.user._id) {
          this.post.youOwn = true;
        }
      }
      this.loaded = true;
    });
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
  .cont-post-viewer{
    padding: 1vh;
    width: calc(100% - 2vh);
    &.not-connected{
      display: flex;
      justify-content: center;
      align-items: center;
      .cont-btn{
        display: none;
      }
    }

    .profile-pic{
      background-color: $logo-color !important;
    }

    .cont-btn{
      margin: 0 0 2vh 0;
    }
    .feed-alone{
      padding: 1.5vh;
      max-width: 900px;
    }
  }
</style>
